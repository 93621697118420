@import '~@angular/material/theming';

@font-face {
  font-family: "Exo";
  src: url("./../fonts/Exo-Regular.ttf")  format('truetype');
  font-display: swap;
}

body.hera {
  background: rgb(82,78,78);
  background: linear-gradient(90deg, rgba(82,78,78,1) 0%, rgba(101,81,81,1) 50%, rgba(97,97,97,1) 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.hera {
  body, fieldset, .mat-table, .breadcrumb, .mat-list-item, mat-form-field, button {
    font-family: "Exo", sans-serif !important;
  }

  $white: #ffffff;
  $white-hover: #ffffff99;
  $white-disabled: #ffffff80;
  $primary: #000000;
  $primary-hover: #00000099;
  $primary-active: #000000;
  $primary-disabled: #00000080;
  $secondary: #4c4f54;
  $secondary-hover: #4c4f5499;
  $secondary-active: #343639;
  $secondary-disabled: #868e9680;
  $info: #4799eb;
  $info-hover: #4799eb99;
  $info-active: #1a80e6;
  $info-disabled: #4799eb80;
  $danger: #d16767;
  $danger-hover: #d1676799;
  $danger-active: #c54040;
  $danger-disabled: #d1676780;
  $approve: #45a164;
  $approve-hover: #45a16499;
  $approve-active: #367d4e;
  $approve-disabled: #45a16480;
  $state-pending: #ffd500;
  $state-rejected: #fe7096;
  $state-approved: #07cdae;
  $state-ongoing: #047edf;
  $state-closed: #3e4b5b;
  $state-cancelled: #FF645C;
  $state-delivering: #acaccc;
  $state-delivered: #4CD964;
  $state-confirmed: #9a55ff;
  $state-returned: #7b5279;
  $state-waiting: #b58276;
  $state-active: #02AC45;
  $state-expired: #A7A7A7;
  $state-inactive: #6AC7B7;
  $icon-disabled: #bfbfbf;

  button {
    i {
      font-weight: 300;
    }

    // Button
    &.mat-button {
      height: 40px;
      font-size: 13px;
      margin-right: 5px;
      border-width: 1px;
      border-style: solid;
      border-radius: 4px;

      &.dashed {
        border-style: dashed;
      }

      &.primary {
        color: $white;
        background-color: $primary;
        border-color: $primary;

        i {
          color: $white;
        }

        &:focus, &:hover {
          color: $white;
          background-color: $primary-hover;
          border-color: $primary-hover;
        }

        &:active {
          color: $white;
          background-color: $primary-active;
          border-color: $primary-active;
        }

        &[disabled] {
          color: $white;
          background-color: $primary-disabled;
          border-color: $primary-disabled;
        }

        &.outline {
          color: $white;
          background-color: transparent;
          border-color: $primary;

          i {
            color: $white;
          }

          &:focus, &:hover {
            color: $white-hover;
            background-color: transparent;
            border-color: $primary;

            i {
              color: $white-hover;
            }
          }

          &:active {
            color: $white;
            background-color: transparent;
            border-color: $primary;

            i {
              color: $white;
            }
          }

          &[disabled] {
            color: $white-disabled;
            background-color: transparent;
            border-color: $white-disabled;

            i {
              color: $white-disabled;
            }
          }
        }

        &.dashed {
          color: $white;
          background-color: transparent;
          border-color: $primary;

          i {
            color: $white;
          }

          &:focus, &:hover {
            color: $white-hover;
            background-color: transparent;
            border-color: $primary-hover;

            i {
              color: $white-hover;
            }
          }

          &:active {
            color: $white;
            background-color: transparent;
            border-color: $primary-active;

            i {
              color: $white;
            }
          }

          &[disabled] {
            color: $white-disabled;
            background-color: transparent;
            border-color: $primary-disabled;

            i {
              color: $white-disabled;
            }
          }
        }
      }

      &.secondary {
        color: $white;
        background-color: $secondary;
        border-color: $secondary;

        i {
          color: $white;
        }

        &:focus, &:hover {
          color: $white;
          background-color: $secondary-hover;
          border-color: $secondary-hover;
        }

        &:active {
          color: $white;
          background-color: $secondary-active;
          border-color: $secondary-active;
        }

        &[disabled] {
          color: $white;
          background-color: $secondary-disabled;
          border-color: $secondary-disabled;
        }

        &.outline {
          color: $secondary;
          background-color: transparent;
          border-color: $secondary;

          i {
            color: $secondary;
          }

          &:focus, &:hover {
            color: $secondary-hover;
            background-color: transparent;
            border-color: $secondary-hover;

            i {
              color: $secondary-hover;
            }
          }

          &:active {
            color: $secondary;
            background-color: transparent;
            border-color: $secondary;

            i {
              color: $secondary;
            }
          }

          &[disabled] {
            color: $secondary-disabled;
            background-color: transparent;
            border-color: $secondary-disabled;

            i {
              color: $secondary-disabled;
            }
          }
        }

        &.dashed {
          color: $secondary;
          background-color: transparent;
          border-color: $secondary;

          i {
            color: $secondary;
          }

          &:focus, &:hover {
            color: $secondary-hover;
            background-color: transparent;
            border-color: $secondary-hover;

            i {
              color: $secondary-hover;
            }
          }

          &:active {
            color: $white;
            background-color: transparent;
            border-color: $secondary-active;

            i {
              color: $white;
            }
          }

          &[disabled] {
            color: $secondary-disabled;
            background-color: transparent;
            border-color: $secondary-disabled;

            i {
              color: $secondary-disabled;
            }
          }
        }
      }

      &.gray {
        color: $white;
        background-color: #7A7A7A;
        border-color: #7A7A7A;

        i {
          color: $white;
        }

        &:focus, &:hover {
          color: $white;
          background-color: #67676799;
          border-color: #67676799;
        }

        &:active {
          color: $white;
          background-color: #F3A101;
          border-color: #F3A101;
        }

        &[disabled] {
          color: $white;
          background-color: #FFA90380;
          border-color: #FFA90380;
        }

        &.outline {
          color: #7A7A7A;
          background-color: $white;
          border-color: #7A7A7A;

          i {
            color: #7A7A7A;
          }

          &:focus, &:hover {
            color: $white;
            background-color: #A7A7A7;
            border-color: #A7A7A7;

            i {
              color: $white;
            }
          }

          &:active {
            color: $white;
            background-color: #7A7A7A;
            border-color: #7A7A7A;

            i {
              color: $white;
            }
          }

          &[disabled] {
            color: #7A7A7A80;
            background-color: $white-disabled;
            border-color: #7A7A7A80;

            i {
              color: #7A7A7A80;
            }
          }
        }

        &.dashed {
          color: #FFBE41;
          background-color: $white;
          border-color: #FFBE41;

          i {
            color: #FFBE41;
          }

          &:focus, &:hover {
            color: #FFBE4199;
            background-color: $white-hover;
            border-color: #FFBE4199;

            i {
              color: #FFBE4199;
            }
          }

          &:active {
            color: $white;
            background-color: #FFBE41;
            border-color: #FFBE41;

            i {
              color: $white;
            }
          }

          &[disabled] {
            color: #FFBE4180;
            background-color: $white-disabled;
            border-color: #FFBE4180;

            i {
              color: #FFBE4180;
            }
          }
        }
      }

      &.info {
        color: $white;
        background-color: $info;
        border-color: $info;

        i {
          color: $white;
        }

        &:focus, &:hover {
          color: $white;
          background-color: $info-hover;
          border-color: $info-hover;
        }

        &:active {
          color: $white;
          background-color: $info-active;
          border-color: $info-active;
        }

        &[disabled] {
          color: $white;
          background-color: $info-disabled;
          border-color: $info-disabled;
        }

        &.outline {
          color: $info;
          background-color: $white;
          border-color: $info;

          i {
            color: $info;
          }

          &:focus, &:hover {
            color: $white;
            background-color: $info;
            border-color: $info;

            i {
              color: $white;
            }
          }

          &:active {
            color: $white;
            background-color: $info-active;
            border-color: $info-active;

            i {
              color: $white;
            }
          }

          &[disabled] {
            color: $info-disabled;
            background-color: $white-disabled;
            border-color: $info-disabled;

            i {
              color: $info-disabled;
            }
          }
        }

        &.dashed {
          color: $info;
          background-color: $white;
          border-color: $info;

          i {
            color: $info;
          }

          &:focus, &:hover {
            color: $info-hover;
            background-color: $white-hover;
            border-color: $info-hover;

            i {
              color: $info-hover;
            }
          }

          &:active {
            color: $info-active;
            background-color: $white;
            border-color: $info-active;

            i {
              color: $info-active;
            }
          }

          &[disabled] {
            color: $info-disabled;
            background-color: $white-disabled;
            border-color: $info-disabled;

            i {
              color: $info-disabled;
            }
          }
        }
      }

      &.danger {
        color: $white;
        background-color: $danger;
        border-color: $danger;

        i {
          color: $white;
        }

        &:focus, &:hover {
          color: $white;
          background-color: $danger-hover;
          border-color: $danger-hover;
        }

        &:active {
          color: $white;
          background-color: $danger-active;
          border-color: $danger-active;
        }

        &[disabled] {
          color: $white-disabled;
          background-color: $danger-disabled;
          border-color: $danger-disabled;
        }

        &.outline {
          color: $danger;
          background-color: $white;
          border-color: $danger;

          i {
            color: $danger;
          }

          &:focus, &:hover {
            color: $white;
            background-color: $danger;
            border-color: $danger;

            i {
              color: $white;
            }
          }

          &:active {
            color: $white;
            background-color: $danger-active;
            border-color: $danger-active;

            i {
              color: $white;
            }
          }

          &[disabled] {
            color: $danger-disabled;
            background-color: $white-disabled;
            border-color: $danger-disabled;

            i {
              color: $danger-disabled;
            }
          }
        }

        &.dashed {
          color: $danger;
          background-color: $white;
          border-color: $danger;

          i {
            color: $danger;
          }

          &:focus, &:hover {
            color: $danger-hover;
            background-color: $white-hover;
            border-color: $danger-hover;

            i {
              color: $danger;
            }
          }

          &:active {
            color: $danger-active;
            background-color: $white;
            border-color: $danger-active;

            i {
              color: $danger;
            }
          }

          &[disabled] {
            color: $danger-disabled;
            background-color: $white-disabled;
            border-color: $danger-disabled;

            i {
              color: $danger-disabled;
            }
          }
        }
      }

      &.approve {
        color: $white;
        background-color: #02AC45;
        border-color: #02AC45;

        i {
          color: $white;
        }

        &:focus, &:hover {
          color: $white;
          background-color: #0CBF53;
          border-color: #0CBF53;
        }

        &:active {
          color: $white;
          background-color: #029E40;
          border-color: #029E40;
        }

        &[disabled] {
          color: $white-disabled;
          background-color: #02AC4580;
          border-color: #02AC4580;

          i {
            color: $white-disabled;
          }
        }
      }
    }

    // Icon
    &.mat-icon-button {
      background-color: transparent;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      line-height: initial;

      i, mat-icon {
        font-size: 14px;
        font-weight: 900;
      }

      &.primary {
        background-color: #DBF7E0;

        i, mat-icon {
          color: $primary;
        }

        &:focus, &:hover {
          border: 1px solid $primary;

          i, mat-icon {
            color: $primary;
          }
        }

        &[disabled] {
          background-color: transparent;
          border: none;

          i, mat-icon {
            color: $icon-disabled;
          }
        }
      }

      &.secondary {
        background-color: #FFEFD2;

        i, mat-icon {
          color: $secondary;
        }

        &:focus, &:hover {
          border: 1px solid $secondary;

          i, mat-icon {
            color: $secondary;
          }
        }

        &[disabled] {
          background-color: transparent;
          border: none;

          i, mat-icon {
            color: $icon-disabled;
          }
        }
      }

      &.gray {
        background-color: #676767;

        i, mat-icon {
          color: #7A7A7A;
        }

        &:focus, &:hover {
          border: 1px solid #676767;

          i, mat-icon {
            color: #676767;
          }
        }

        &[disabled] {
          background-color: transparent;
          border: none;

          i, mat-icon {
            color: $icon-disabled;
          }
        }
      }

      &.info {
        background-color: #E6F2FC;

        i, mat-icon {
          color: $info;
        }

        &:focus, &:hover {
          border: 1px solid $info;

          i, mat-icon {
            color: $info;
          }
        }

        &[disabled] {
          background-color: transparent;
          border: none;

          i, mat-icon {
            color: $icon-disabled;
          }
        }
      }

      &.danger {
        background-color: #FFF0EF;

        i, mat-icon {
          color: $danger;
        }

        &:focus, &:hover {
          border: 1px solid $danger;

          i, mat-icon {
            color: $danger;
          }
        }

        &[disabled] {
          background-color: transparent;
          border: none;

          i, mat-icon {
            color: $icon-disabled;
          }
        }
      }

      &.approve {
        background-color: #DBF7E0;

        i, mat-icon {
          color: $approve;
        }

        &:focus, &:hover {
          border: 1px solid $approve;

          i, mat-icon {
            color: $approve;
          }
        }

        &[disabled] {
          background-color: transparent;
          border: none;

          i, mat-icon {
            color: $icon-disabled;
          }
        }
      }
    }

    // State
    &.mat-state-button {
      font-size: 11px;
      font-weight: bold;
      height: 24px;
      min-width: 100px;
      border-radius: 15px;
      margin-left: 5px;
      border: none;
      outline: none;

      &.pending {
        color: $white;
        background-color: $state-pending;

        &.rectangle {
          color: $state-pending;
          background-color: transparent;
        }
      }

      &.rejected {
        color: $white;
        background-color: $state-rejected;

        &.rectangle {
          color: $state-rejected;
          background-color: transparent;
        }
      }

      &.approved {
        color: $white;
        background-color: $state-approved;

        &.rectangle {
          color: $state-approved;
          background-color: transparent;
        }
      }

      &.ongoing {
        color: $white;
        background-color: $state-ongoing;

        &.rectangle {
          color: $state-ongoing;
          background-color: transparent;
        }
      }

      &.closed {
        color: $white;
        background-color: $state-closed;

        &.rectangle {
          color: $state-closed;
          background-color: transparent;
        }
      }

      &.cancelled {
        color: $white;
        background-color: $state-cancelled;

        &.rectangle {
          color: $state-cancelled;
          background-color: transparent;
        }
      }

      &.delivering {
        color: $white;
        background-color: $state-delivering;

        &.rectangle {
          color: $state-delivering;
          background-color: transparent;
        }
      }

      &.delivered {
        color: $white;
        background-color: $state-delivered;

        &.rectangle {
          color: $state-delivered;
          background-color: transparent;
        }
      }

      &.confirmed {
        color: $white;
        background-color: $state-confirmed;

        &.rectangle {
          color: $state-confirmed;
          background-color: transparent;
        }
      }

      &.returned {
        color: $white;
        background-color: $state-returned;

        &.rectangle {
          color: $state-returned;
          background-color: transparent;
        }
      }

      &.waiting {
        color: $white;
        background-color: $state-waiting;

        &.rectangle {
          color: $state-waiting;
          background-color: transparent;
        }
      }

      &.active {
        color: $white;
        background-color: $state-active;

        &.rectangle {
          color: $state-active;
          background-color: transparent;
        }
      }

      &.expired {
        color: $white;
        background-color: $state-expired;

        &.rectangle {
          color: $state-expired;
          background-color: transparent;
        }
      }

      &.inactive {
        color: $white;
        background-color: $state-inactive;

        &.rectangle {
          color: $state-inactive;
          background-color: transparent;
        }
      }

      &.rectangle:after {
        content: "\f111";
        font-family: "Font Awesome 5 Pro";
        font-size: 10px;
        position: relative;
        top: -1px;
        left: 5px;
      }
    }
  }

  .mat-toolbar {
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(66,66,66,1) 50%, rgba(50,60,68,1) 100%);
    box-shadow: 0px 0px 2px #333333, 3px 2px 5px #333333;
  }

  mat-sidenav {
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(66,66,66,1) 50%, rgba(50,60,68,1) 100%);
    box-shadow: 0px 0px 2px #333333, 3px 2px 5px #333333;
  }

  mat-sidenav-container {
    background-color: transparent;
    color: rgba(255,255,255,.87);
  }

  mat-sidenav-content {
    background-color: transparent;
  }

  // Menu
  app-menu-list-item {
    &[aria-expanded="true"] {
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 5px;
    }

    .mat-list-item {
      height: auto;
      font-size: 13px;
      border-bottom: 1px dotted rgba(255,255,255,0.2);

      &.expanded {
        border-bottom: none;

        .mat-list-item {
          font-size: 13px;
        }
      }

      .mat-list-item-content {
        color: #c2c2c2;
      }

      .mat-icon {
        color: #c2c2c2;
      }

      &:hover .mat-icon,
      &:hover .mat-list-item-content,
      &.active .mat-icon,
      &.active .mat-list-item-content {
        color: white;
      }
    }

    app-menu-list-item {
      border-bottom: none;

      .mat-list-item {
        padding: 0 !important;
        font-size: 13px;

        .mat-list-item-content {
          border: none;
        }

        .mat-icon {
          display: none;
        }
      }
    }
  }

  // Table
  .table-responsive {
    .mat-table {
      border: none;

      .mat-header-row {
        .mat-header-cell, .mat-header-cell .viewData, .mat-header-cell div {
          color: white;
          font-weight: bold;
        }
      }

      .mat-row {
        &:nth-of-type(even) {
          background-color: #1e1e1e;
        }

        &:hover {
          background-color: #3a3f42;
        }

        &.parentRow0, &.childRow0 {
          background-color: #1e1e1e;
        }

        &.parentRow1, &.childRow1 {
          background-color: #1e1e1e;
        }

        .mat-cell {
          &.mat-column-status {
            width: 80px;
            font-weight: bold;
          }

          .viewData {
            color: white;

            &.pending {
              color: $state-pending;
            }

            &.rejected {
              color: $state-rejected;
            }

            &.approved {
              color: $state-approved;
            }

            &.ongoing {
              color: $state-ongoing;
            }

            &.closed {
              color: $state-closed;
            }

            &.cancelled {
              color: $state-cancelled;
            }

            &.delivering {
              color: $state-delivering;
            }

            &.delivered {
              color: $state-delivered;
            }

            &.confirmed {
              color: $state-confirmed;
            }

            &.returned {
              color: $state-returned;
            }

            &.waiting {
              color: $state-waiting;
            }
          }
        }
      }

      .mat-footer-cell .viewData {
        font-size: 16px;
      }

      .mat-row, .mat-header-row, .mat-footer-row, .mat-header-cell, .mat-cell, .mat-footer-cell {
        border-bottom: 1px solid #444444;
      }

      .mat-cell, .mat-footer-cell {
        color: white;
      }
    }

    &.fixed_header {
      box-shadow: none;

      thead {
        .mat-header-cell {
          background-color: #1e1e1e;
          z-index: 1;
        }
      }

      tbody {

      }

      tfoot {
        &.mat-table-sticky {
          background-color: #1e1e1e;

          tr {
            .mat-footer-cell {
              border-top: none;
              border-bottom: none;
            }

            &:first-child {
              .mat-footer-cell {
                border-top: 1px solid #444444;
              }
            }
          }

        }
      }
    }

    tr:last-child .mat-cell,
    .mat-header-cell,
    &.fixed_header tfoot.mat-table-sticky tr:last-child .mat-footer-cell {
      border-bottom: 1px solid #444444;
    }
  }

  // Mat tab
  mat-tab-group {
    .mat-tab-header {
      margin-bottom: 5px;
      border-bottom: none;

      .mat-tab-label-container {
        .mat-tab-list {
          .mat-tab-labels {
            .mat-tab-label {
              border-radius: 20px;

              .mat-tab-label-content {
                background-color: rgba(0,0,0,0.4);

                &:hover {
                  background-color: #263238;
                  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
                }
              }

              &.mat-tab-label-active {
                .mat-tab-label-content {
                  background-color: #263238;
                }

                &:before {
                  color:#F44336;
                  top: 4px;
                  left: 7px;
                }
              }
            }
          }
        }
      }
    }

    .mat-tab-body-wrapper {
      .mat-tab-body {
        ns-multi-select-autocomplete .mat-form-field {
          background-color: transparent !important;
        }
      }
    }

    .box-search {
      background-color:rgba(0, 0, 0, 0.2);
    }

    .mat-ink-bar {
      background-color:#607D8B;
    }
  }

  // Paging
  mat-paginator {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .mat-paginator-page-size .mat-select-trigger {
      font-size: 14px !important;
    }

    .mat-icon-button {
      line-height: 30px !important;
    }

    .mat-custom-page {
      color: $secondary !important;
      font-size: 14px;

      &:hover {
        color: $info !important;
      }

      &[disabled="disabled"] {
        color: $info !important;
      }
    }

    .mat-mini-fab.mat-custom-page {
      line-height: 40px;
    }

    .mat-focus-indicator {
      color: white !important;
      background-color: transparent !important;

      &.mat-icon-button.mat-button-disabled,
      &.mat-button.mat-button-disabled {
        color: $secondary !important;
      }
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline {
      background-color: white;
      bottom: 10px !important;
    }
  }

  .go-to-page {
    .mat-button-base {
      color: $info;
    }
  }

  // Form field
  mat-form-field {
    .mat-form-field-wrapper {
      .mat-form-field-flex {

        &:focus .mat-form-field-outline,
        &:hover .mat-form-field-outline {

        }

        .mat-form-field-outline-thick,
        .mat-form-field-outline {
          border: 1px solid white;
          top: 0;
        }

        .mat-form-field-outline {
          .mat-form-field-outline-start,
          .mat-form-field-outline-gap,
          .mat-form-field-outline-end {
            border: none;
          }
        }
      }
    }

    &.mat-form-field-disabled {
      .mat-form-field-flex {
        &:hover .mat-form-field-outline,
        .mat-form-field-outline {
          color: #ccc;
          background-color: #ccc;
          border-color: #ccc;
        }
      }
    }

    &.mat-focused {
      .mat-form-field-label {
        color:#ffa589 !important;
      }
    }

    .mat-form-field-infix {
      border: none;
      padding: 15px 0;

      .mat-form-field-label-wrapper {
        top: 0;
        padding-top: 0;
        display: flex;
        align-items: center;
        overflow: visible;

        .mat-form-field-label {
          top: initial;
          margin-top: 0;
        }
      }
    }

    &.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-30px) scale(0.8) !important;
      width: 100% !important;
    }

    &.mat-form-field-appearance-outline {
      .mat-form-field-outline {
        color: rgba(255, 255, 255, 0.4) !important;
      }

      .mat-form-field-outline-thick {
        color: rgba(255, 255, 255, 0.6) !important;
      }

      &.mat-focused .mat-form-field-outline-thick {
        color: white;
      }
    }

    .mat-datepicker-toggle button {
      margin-top: 4px;
    }
  }

  .mat-tab-nav-bar.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-toolbar .mat-ink-bar {
    background-color:#607D8B;
  }

  ::-webkit-scrollbar-thumb {
    background: #90a4ae !important;
  }

  :root {
    scrollbar-color: rgb(96, 125, 139) rgb(31, 33, 35);
  }

  a {
    color: #009688;
  }

  a:hover {
    color: #00bcd4;
  }

  .breadcrumb i.fa-home {
    color: #607d8b;
  }

  .username i,
  .theme_select i,
  .theme_select:hover span,
  .breadcrumb i:not(.fa-home),
  .mat-tab-label,
  .breadcrumb a:hover {
    color:#9e9e9e  !important;
  }

  .mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
    color:#9E9E9E;
  }

  .open_nav_button,
  .username span,
  .theme_select span
  {
    color:#607d8b;
  }

  .breadcrumb span.currentAction {
    color: #b0bec5 !important;
  }

  .mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
    color: #605b5b;
    background-color: #ccccd5;
  }

  .mat-flat-button.mat-primary[disabled]:hover, .mat-flat-button.mat-accent[disabled]:hover, .mat-flat-button.mat-warn[disabled]:hover, .mat-flat-button[disabled]:hover[disabled]:hover, .mat-raised-button.mat-primary[disabled]:hover, .mat-raised-button.mat-accent[disabled]:hover, .mat-raised-button.mat-warn[disabled]:hover, .mat-raised-button[disabled]:hover[disabled]:hover, .mat-fab.mat-primary[disabled]:hover, .mat-fab.mat-accent[disabled]:hover, .mat-fab.mat-warn[disabled]:hover, .mat-fab[disabled]:hover[disabled]:hover, .mat-mini-fab.mat-primary[disabled]:hover, .mat-mini-fab.mat-accent[disabled]:hover, .mat-mini-fab.mat-warn[disabled]:hover, .mat-mini-fab[disabled]:hover[disabled]:hover {
    background-color: #9a99ac;
  }

  mat-table {
    border:none !important;
    box-shadow: 0px 0px 2px #333333, 3px 2px 5px #333333 !important;
  }

  mat-header-row {
    background-color:#1f2123 !important;
  }

  mat-header-cell, mat-header-cell .viewData, mat-header-cell div {
    color:#607d8b;
  }

  .searchFormGroup .form, .searchFormGroup .content {
    background-color:#424242;
    box-shadow: 0px 0px 2px #333333, 3px 2px 5px #333333 !important;
  }

  label.item-focused,
  .breadcrumb a {
    color:#607D8B !important;
  }

  .mat-checkbox-inner-container {
    height:20px;
    width:20px;
  }

  mat-table > mat-row:nth-of-type(even) {
    background-color: #263238 !important;
  }

  fieldset {
    background-color:#424242;
    box-shadow: 0px 0px 2px #333333, 3px 2px 5px #333333 !important;
  }

  fieldset legend {
    color:#90a4ae !important;
    background-color:transparent;
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled), .mat-toolbar .mat-option.mat-selected:not(.mat-option-disabled) {
    color:#78909C;
  }

  h1, h2, h3, h4, h5, h6, h7 {
    color: #D4DEE1;
  }

  .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-indeterminate.mat-toolbar .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-toolbar .mat-checkbox-background {
    background-color:#9E9E9E;
  }

  .mat-primary .mat-pseudo-checkbox-checked, .mat-toolbar .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate, .mat-toolbar .mat-pseudo-checkbox-indeterminate {
    background-color:#9E9E9E;
  }

  .mat-input-element {
    caret-color: white;
  }

  .uploadArea button {
    background-color:#263238;
    border-radius: 0;
  }

  .resizer:before {
    color:#607d8b;
  }

  .ql-snow .ql-picker {
    color: white;
  }

  .ql-toolbar.ql-snow {
    border: 1px solid rgba(255, 255, 255, 0.4);
  }

  .ql-snow .ql-picker.ql-expanded .ql-picker-label,
  .ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: #607d8b;
  }

  .ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill ,
  .ql-snow .ql-stroke {
    stroke: white;
  }

  .ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: #607d8b;
  }

  mat-expansion-panel {
    background-color: #2a2a2a;
    box-shadow: none !important;
    color: white;

    .mat-expansion-panel-header {
      background-color: #1e1e1e;

      .mat-content {
        .mat-expansion-panel-header-title {

        }

        .mat-expansion-panel-header-description {
          .mat-icon {

          }
        }
      }

      .mat-expansion-indicator {

      }
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {

      }
    }

    &.mat-expanded {
      .mat-expansion-panel-header {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .mat-expansion-panel-header.mat-expanded:focus, .mat-expansion-panel-header.mat-expanded:hover,
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background-color: #1e1e1e;
  }

  .mat-badge-content {
    color: white;
    background-color: #607D8B;
  }

  .acbox {
    background-color: #2a2a2a;
    border-radius: 5px;
    box-shadow:  0px 0px 2px #333333, 3px 2px 5px #333333;
  }

  .mat-select-panel {
    background-color: #2a2a2a;
    box-shadow:  0px 0px 2px #000000, 3px 2px 5px #000000;
    color: white;
  }
}

@media screen and (max-width: 767px) {
  .hera .table-responsive.fixed_header mat-header-cell {
    background-color: #1f2123 !important;
  }
}
