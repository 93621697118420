@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@import '~@angular/material/theming';


////////////////////////////////////////////////////////////////////
// CORE NOT CHANGE :(
////////////////////////////////////////////////////////////////////
@include mat-core();

@import "base.theme";
mat-form-field {
  width: 100%;
}

td.mat-column-edit,
td.mat-column-detail,
td.mat-column-accept,
td.mat-column-reject,
td.mat-column-stt,
td.mat-column-checked {
  width: 50px;
  max-width: 50px;
}

.default .table-responsive .mat-table .mat-row .mat-cell.mat-column-status {
  width: auto;
  font-weight: bold;
}

.ns-quill-editor .ql-container.ql-snow {
  height: 250px !important;
  overflow-y: auto;
}

.dialog-view-image {
  width: 80vw !important;
  height: 80vh !important;

  mat-dialog-container {
    width: 100% !important;
    //display: flex;
    //align-items: center;
    //place-content: center;
  }
}

.dialog-carousel {
  width: 100vw !important;
  height: 100vh !important;

  .slideshow-container {
    height: 93%;
  }

}
