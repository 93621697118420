.ns-tree {
  .example-tree-invisible {
    display: none;
  }

  .example-tree ul,
  .example-tree li {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
    height: 100%;
    width: 100%;
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: fill-available;
  }

  .parent-mat-tree {
    min-height: calc(100% - 53px);
    max-height: calc(100% - 53px);
    overflow: auto;
    transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);
    margin: 1px;
    border-radius: 2px;
  }

  .parent-mat-tree mat-tree {
    height: 100%;
  }

  .parent-mat-tree mat-tree mat-nested-tree-node {
    height: 100%;
  }

  mat-tree mat-nested-tree-node div.mat-tree-node {
    display: flex;
    align-items: center;
    min-height: 36px;
    height: 36px;
    flex: 1;
    word-wrap: break-word;
  }

  mat-tree mat-nested-tree-node ul mat-tree-node.mat-tree-node {
    display: flex;
    align-items: center;
    min-height: 36px;
    height: 36px;
    flex: 1;
    word-wrap: break-word;
  }

  .box-search {
    margin: 1px;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);
    display: flex;
  }

  .box-search .expand_collapse button {
    padding-top: 6px;
  }

  .box-search ns-multi-select-autocomplete {
    flex: 1;
    border: none;
    outline: none;
    padding-left: 1rem;
  }

  label.item-focused {
    font-weight: bold;
    color: #ff9800;
  }
}
