@font-face {
    font-family: 'Glyphter';
    src: url('./../fonts/Glyphter.eot');
    src: url('./../fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
         url('./../fonts/Glyphter.woff') format('woff'),
         url('./../fonts/Glyphter.ttf') format('truetype'),
         url('./../fonts/Glyphter.svg#Glyphter') format('svg');
    font-weight: normal;
    font-style: normal;
  font-display: swap;
}

[class*='icon-']:before{
	display: inline-block;
   font-family: 'Glyphter';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}

.icon-campaign:before{content:'\0041';}
.icon-complaint:before{content:'\0042';}
.icon-distributor:before{content:'\0043';}
.icon-finance:before{content:'\0044';}
.icon-price:before{content:'\0045';}
