.progress-loader {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
}

.progress-loader img {
  max-width: 150px;
  max-height: 150px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}

.loading-spinner {
  background-color: darkgrey;
  opacity: 0.5;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  display: grid;
}

.loading-spinner mat-progress-spinner {
  display: block;
  position: relative;
  margin: 0px auto;
}
